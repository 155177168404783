<template>
  <v-row justify="center">
    <v-col
      cols="12"
      sm="10"
      md="8"
      lg="6">
      <v-form ref="add_receiver" @submit.prevent="saveAddress()">
      <v-card  class="card flat-card" color="surface">
        <v-card-text>

            <div class="d-flex align-center justify-center">
          <div class="type-container mt-4">
            <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
            <v-btn class="accent button small-button onAccent--text px-1" v-bind="attrs" v-on="on">
              <v-icon  class="onAccent--text" size="20" v-text="type.title"></v-icon>
              <v-icon small class="onAccent--text">mdi-chevron-down</v-icon>
            </v-btn>
            </template>
            <v-list>
            <v-list-item v-for="(item, index) in items" :key="index">
              <v-list-item-title @click="type_address(item)">
                <v-icon class="onAccent--text" size="20">
                  {{ item.title }}
                </v-icon>
              </v-list-item-title>
            </v-list-item>
      </v-list>
            </v-menu>
          </div>
          <div class="title-container ">
            <v-text-field
            label="عنوان*"
            class="my-auto"
            v-model="address.title"
            required></v-text-field>
          </div>
          </div>
            <v-text-field
            label="تحویل گیرنده*"
            maxlength="21"
            :rules="[ rules.required, rules.count ]"
            v-model="address.receiver"
            required></v-text-field>
             <v-text-field
             disabled
            v-model="Province"
            label="استان*"
            required></v-text-field>
          <v-text-field
            v-model="city"
            disabled
            label="شهر*"
            required></v-text-field>
            <v-combobox @change="set_zone"
             v-model="zone.title"
             :items="zones_title"
             :rules="[ rules.required, rules.set_zone ]"
             label="ناحیه*"></v-combobox>
          <v-text-field
            label="آدرس*"
            maxlength="201"
            :rules="[ rules.required, rules.address ]"
            v-model="address.address"
            required></v-text-field>
            <v-text-field
            v-model="address.postal_code"
            label="کدپستی"
            maxlength="12"
            :rules="[ rules.post_code, rules.type_number ]"
            type="tell"
            ></v-text-field>
           <v-text-field
           v-model="address.phone"
            label="تلفن ثابت"
            maxlength="11"
            :rules="[ rules.phone, rules.type_number ]"
            type="tell"
            ></v-text-field>
           <v-text-field
           v-model="address.mobile"
           maxlength="11"
            label="شماره همراه*"
            :rules="[ rules.required, rules.mobile, rules.type_number ]"
            type="tell"
            required></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          @click="check()"
            :class="{'is-loading': loading}" :disabled="loading" 
            type="submit"
            class="button primaryActions onPrimaryActions--text"
            ><loading v-if="loading" class="loading"/>
            ذخیره اطلاعات
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-col>
  </v-row>
</template>
<style lang="scss" scoped>
.is-loading {
  color:transparent;
}
.loading{
  font-size: 8px;
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}
.type-container{
  width: 20%;
}
.title-container{
  width: 80%;
}
.v-list-item{
  min-height: 35px;
}
</style>
<script>
import loading from '@/components/tools/loading'
import {alertMessage} from '@/assets/messageAlert'
import { setReceiver } from '../../models/Basket'
import { mapState } from 'vuex'
import { toEnglishDigits } from '@/models/changeType'
import { zones } from '@/models/getProvinces'
  export default {
    components:{
      loading
    },
    created () {
      zones()
    },
    computed: {
      ...mapState({
        user: state => state.user.user,
        _zones: state => state.address.zones,
        zones_title: state => state.address.zones_title
      })
    },
    watch: {
      user: {
        handler: function () {
          this.address.receiver = this.user.first_name+ ' ' + this.user.last_name
          this.address.mobile = this.user.mobile
        }
      }
    },
    data: () => ({
      zone: {
        title: '',
        id: ''
      },
       items: [
        { title: 'mdi-home', type: 1 },
        { title: 'mdi-office-building', type: 2 },
      ],
      type: { title: 'mdi-home', type: 1 },
      array: [],
      loading: false,
      address: {
         province: 18, province_name: 'خراسان رضوي', city: 182,
         city_name: 'مشهد', phone: '', title: '', type: '',
         mobile: '', receiver: '',
         address: '', postal_code: '',
         latitude: 0, longitude: 0 
      },
      Province: 'خراسان رضوی',
      city: 'مشهد',
      rules: {
        required: null,
        count: value => value.length <= 20 || alertMessage.error['MaxCharacters'],
        phone: value => value.slice(0,2) == '05' || alertMessage.error['typePhone'],
        mobile: value => {
          const mobile = toEnglishDigits(value)
          return mobile.slice(0,2) == '09' || alertMessage.error['typePhone']},
        address: value => value.length <= 200 || alertMessage.error['address'],
        post_code: value => value.length <= 10 || alertMessage.error['post_code'],
        type_number: value => {
          const mobile = toEnglishDigits(value)
        const pattern = /^[0-9]+$/
        return pattern.test(mobile) || alertMessage.error['type_number']
      },
      set_zone: null
      }
    }),
    methods: {
      type_address (item) {
        this.type = item
      },
      set_zone () {
        for (const zone of this._zones) {
          if (zone.title == this.zone.title) {
            this.zone.id = zone.id
            this.rules.set_zone = value => !!value || alertMessage.error['wrong_zone']
            return
          } else {
            this.rules.set_zone = 
              () => alertMessage.error['wrong_zone']
            
          }
        }
      },
      check () {
        this.rules = {
          required: value => !!value || alertMessage.error['Required']
        }
      },
      saveAddress () {
        const validate = this.$refs.add_receiver.validate()
        if (validate == true) {
         const address = { province: 18, province_name: 'خراسان رضوي', city: 182,
         city_name: 'مشهد', phone: toEnglishDigits(this.address.phone), title: this.address.title, type: this.type.type,
         mobile: toEnglishDigits(this.address.mobile), receiver: this.address.receiver,
         address: this.address.address, postal_code: toEnglishDigits(this.address.postal_code),
         latitude: 0, longitude: 0,
         region: this.zone.id }
         setReceiver(address) 
        }
      }
    },
  }
</script>